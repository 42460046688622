var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "link_button",
      attrs: { href: _vm.href, target: _vm.target }
    },
    [
      _c("span", { staticClass: "link_button__text" }, [
        _vm._v(_vm._s(_vm.label))
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }