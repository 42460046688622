var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrap" }, [
    _vm.show
      ? _c("div", { staticClass: "content" }, [
          _vm._m(0),
          _vm._m(1),
          _vm._m(2),
          _vm._m(3),
          _c("button", { on: { click: _vm.hideContent } }, [_c("span")])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("竹田・菊地税理士法人は"),
      _c("br"),
      _vm._v("会社経営のあらゆるシーンを"),
      _c("br", { staticClass: "u-sp-block" }),
      _vm._v("応援します")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "u-pc-flex" }, [
      _c("li", [_vm._v("会社設立")]),
      _c("li", [_vm._v("資金調達")]),
      _c("li", [_vm._v("経営計画")]),
      _c("li", [_vm._v("相続・事業承継")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "contact u-pc-block" }, [
      _c("span", [_vm._v("無料相談のご予約は")]),
      _c("div", { staticClass: "tel" }, [
        _c("i", { staticClass: "icon icon-tel-emerald" }),
        _c("span", [_vm._v("019-623-1361")])
      ]),
      _c("span", [_vm._v("※受付時間 平日 9時～17時")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "contact u-sp-block" }, [
      _c("a", { staticClass: "tel", attrs: { href: "tel:0196231361" } }, [
        _c("i", { staticClass: "icon icon-tel-emerald" }),
        _c("span", [_vm._v("無料相談予約受付中")])
      ]),
      _c("span", [_vm._v("※受付時間9時～17時")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }