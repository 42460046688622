<template lang="pug">
a(:href="href", :target="target").link_button
  span.link_button__text {{ label }}
</template>

<style lang="scss" scoped>
.link_button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 240px;
  padding: 20px;
  background: rgb(253, 177, 228);
  background: linear-gradient(
    90deg,
    rgba(253, 177, 228, 1) 0%,
    rgba(222, 70, 170, 1) 100%
  );

  &__text {
    color: #fff;
    font-weight: 700;
    font-size: 16px;
    position: relative;
    z-index: 2;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(222, 70, 170, 1);
    opacity: 0;
    transition: opacity 0.5s ease;
  }

  @media (hover: hover) {
    &:hover:after {
      opacity: 1;
    }
  }
}
</style>

<script>
import Vue from "vue";

export default Vue.extend({
  props: {
    label: {
      type: String,
      required: true,
      validator(value) {
        return typeof value === "string";
      },
    },
    href: {
      type: String,
      required: true,
      validator(value) {
        return typeof value === "string";
      },
    },
    target: {
      type: String,
      default: "_self",
      validator(value) {
        return ["_self", "_blank"].includes(value);
      },
    },
  }
});
</script>
