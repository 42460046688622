import Vue from 'vue';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';

import GreetingContent from '@vue/top/GreetingContent';
import FloatPopup from '@vue/top/FloatPopup';
import LinkButton from '@vue/tokyo/LinkButton';

/**
 * TOPページの処理を記述
 */
const topFunc = () => {
  // メインビジュアルのスライダー
  Vue.use(VueAwesomeSwiper);
  new Vue({
    el: '#v-mv_slider',
    data() {
      return {
        swiperOptions: {
          initialSlide: 0,
          loop: true,
          slidesPerView: 'auto',
          speed: 500,
          centeredSlides: true,
          autoplay: {
            delay: 10000,
            disableOnInteraction: false
          },
          pagination: {
            el: '.slider-pagination',
            clickable: true,
            bulletActiveClass: 'is-active'
          },
          // spaceBetween: 0,
          simulateTouch: true,
          navigation: {
            nextEl: '.slider-nav--next',
            prevEl: '.slider-nav--prev',
          }
        }
      };
    }
  });

  // 代表挨拶のアコーディオン
  Vue.component('greeting_content', GreetingContent);
  new Vue({
    el: '#v-greeting_content',
  });

  // popup
  new FloatPopup ().$mount('#v-float_popup');

  // ピンクグラデーションのリンクボタン
  Vue.component('link_button', LinkButton);
  new Vue({
    el: '.v-link_button',
  });
};

document.addEventListener('DOMContentLoaded', topFunc);
