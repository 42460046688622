<template lang="pug">
  div.greeting_content(v-bind:class="{active}")
    div.greeting_content__txt_area(ref="text_area")
      p.c-typo--01
        | 人口減少の今日、事業の経営も難しいものとなってきています。このような時代に皆様の事業の存続と発展のために、なにができるのかを常に考え行動してまいりました。
        br
        | 改めて経営理念に「お客様の事業発展に貢献し、社員の幸せな生活の実現を目指します。」と掲げたのも、竹田・菊地税理士法人の姿勢を明らかにし、変化してゆく市場とお客様の要望に応えられるように社内体制の整備と強化をしていくためです。
      p.c-typo--01
        | 現在、竹田・菊地税理士法人では盛岡本社と東京事務所の２拠点で事業を展開しています。
      p.c-typo--01
        | 昭和22年からの歴史を有する盛岡本社では、私と菊地税理士で、従前からの記帳・税務申告をベースにしつつ、より良いサービスの提供と開発に努めています。また、業務品質確保のため、社員の協力を得ながら必要な知識と技術の習得を絶えず続けています。
      p.c-typo--01
        | 平成30年9月に開設した東京事務所では高橋税理士の元、経営サポートと相続に特化したサービスを提供しており、盛岡本社における経営計画書を用いた経営サポート業務も担当しています。東京事務所は中央の最新情報を収集して、盛岡本社での活動に生かし、お客様に還元するという機能も発揮しています。
      p.c-typo--01
        | 盛岡本社と東京事務所の相乗効果と組織力、そして社員各々の能力を合わせて、お客様の明るい未来創りに貢献するため尽力してまいります。変わらぬご支援、ご指導をお願い申し上げます。
      p.c-typo--01.greeting_content__name
        | 竹田・菊地税理士法人 代表社員　竹田富雄
    div.greeting_content__btn.u-sp-block
      button(@click="toggleButton") {{ buttonText }}
        i.icon.icon-plus-darkblue(v-if="!active")
        i.icon.icon-minus-darkblue(v-if="active")
</template>

<style lang="scss" scoped>
  @import './GreetingContent.scss';
</style>

<script>
import Vue from 'vue';

export default {
  data: function() {
    return {
      maxHeight: 0,
      minHeight: 0,
      active: false,
      buttonText: '続きを読む',
    }
  },
  methods: {
    // 開閉のどちらかを振り分け
    toggleButton: function() {
      if(this.active) {
        this.closeContent();
      } else {
        this.openContent();
      }
    },
    // 要素が閉じる場合
    closeContent: function() {
      this.setHeight('min');
      this.active = false;
      this.buttonText = '続きを読む';
    },
    // 要素が開く場合
    openContent: function() {
      this.setHeight('max');
      this.active = true;
      this.buttonText = '閉じる';
    },
    // 要素の高さをセット
    setHeight: function(state) {
      if(state === 'min') {
        this.$refs.text_area.style.height = `${this.minHeight}px`;
      } else if(state === 'max') {
        this.$refs.text_area.style.height = `${this.maxHeight}px`;
      }
    },
    handleResize: function() {
      if (window.innerWidth <= 768) {
        // 高さのリセット
        this.$refs.text_area.style.height = 'auto';

        // 再定義
        this.maxHeight = this.$refs.text_area.clientHeight;
        this.minHeight = this.$refs.text_area.firstChild.clientHeight;

        this.active ? this.openContent(): this.closeContent();
      } else {
        // PCの場合は全てリセット
        this.$refs.text_area.style.height = 'auto';
        this.active = false;
      }
    },
  },
  mounted: function() {
    window.addEventListener('resize', this.handleResize);

    if (window.innerWidth <= 768) {
      // 要素の100%時の高さを取得
      this.maxHeight = this.$refs.text_area.clientHeight;
      this.minHeight = this.$refs.text_area.firstChild.clientHeight;

      this.setHeight('min');
    }
  },
  beforeDestroy: function() {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>
