var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "greeting_content", class: { active: _vm.active } },
    [
      _c(
        "div",
        { ref: "text_area", staticClass: "greeting_content__txt_area" },
        [
          _vm._m(0),
          _c("p", { staticClass: "c-typo--01" }, [
            _vm._v(
              "現在、竹田・菊地税理士法人では盛岡本社と東京事務所の２拠点で事業を展開しています。"
            )
          ]),
          _c("p", { staticClass: "c-typo--01" }, [
            _vm._v(
              "昭和22年からの歴史を有する盛岡本社では、私と菊地税理士で、従前からの記帳・税務申告をベースにしつつ、より良いサービスの提供と開発に努めています。また、業務品質確保のため、社員の協力を得ながら必要な知識と技術の習得を絶えず続けています。"
            )
          ]),
          _c("p", { staticClass: "c-typo--01" }, [
            _vm._v(
              "平成30年9月に開設した東京事務所では高橋税理士の元、経営サポートと相続に特化したサービスを提供しており、盛岡本社における経営計画書を用いた経営サポート業務も担当しています。東京事務所は中央の最新情報を収集して、盛岡本社での活動に生かし、お客様に還元するという機能も発揮しています。"
            )
          ]),
          _c("p", { staticClass: "c-typo--01" }, [
            _vm._v(
              "盛岡本社と東京事務所の相乗効果と組織力、そして社員各々の能力を合わせて、お客様の明るい未来創りに貢献するため尽力してまいります。変わらぬご支援、ご指導をお願い申し上げます。"
            )
          ]),
          _c("p", { staticClass: "c-typo--01 greeting_content__name" }, [
            _vm._v("竹田・菊地税理士法人 代表社員　竹田富雄")
          ])
        ]
      ),
      _c("div", { staticClass: "greeting_content__btn u-sp-block" }, [
        _c("button", { on: { click: _vm.toggleButton } }, [
          _vm._v(_vm._s(_vm.buttonText)),
          !_vm.active
            ? _c("i", { staticClass: "icon icon-plus-darkblue" })
            : _vm._e(),
          _vm.active
            ? _c("i", { staticClass: "icon icon-minus-darkblue" })
            : _vm._e()
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "c-typo--01" }, [
      _vm._v(
        "人口減少の今日、事業の経営も難しいものとなってきています。このような時代に皆様の事業の存続と発展のために、なにができるのかを常に考え行動してまいりました。"
      ),
      _c("br"),
      _vm._v(
        "改めて経営理念に「お客様の事業発展に貢献し、社員の幸せな生活の実現を目指します。」と掲げたのも、竹田・菊地税理士法人の姿勢を明らかにし、変化してゆく市場とお客様の要望に応えられるように社内体制の整備と強化をしていくためです。"
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }