<template lang="pug">
  div.wrap
    div.content(v-if="show")
      p
        | 竹田・菊地税理士法人は
        br
        | 会社経営のあらゆるシーンを
        br.u-sp-block
        | 応援します
      ul.u-pc-flex
        li 会社設立
        li 資金調達
        li 経営計画
        li 相続・事業承継
      div.contact.u-pc-block
        span 無料相談のご予約は
        div.tel
          i.icon.icon-tel-emerald
          span 019-623-1361
        span ※受付時間 平日 9時～17時
      div.contact.u-sp-block
        a.tel(href="tel:0196231361")
          i.icon.icon-tel-emerald
          span 無料相談予約受付中
        span ※受付時間9時～17時
      button(v-on:click="hideContent")
        span
</template>

<style lang="scss" scoped>
  @import './FloatPopup.scss';
</style>

<script>
import Vue from 'vue';

export default Vue.extend ({
  data: function() {
    return {
      show: false,
    }
  },
  methods: {
    hideContent: function() {
      this.$data.show = false;
    },
    showContent: function() {
      this.$data.show = true;
      // ローカルストレージにフラグを格納
      localStorage["takedakikuchi_visited-flag"] = true;
    }
  },
  mounted: function() {
    // ローカルストレージにフラグが存在しなかったら表示する
    const localstorage_flg = localStorage["takedakikuchi_visited-flag"];
    if(!localstorage_flg) {
      this.showContent();
    }
  }
});
</script>
